import ChartDatasets from "./chartDatasetsModel";

export default class ChartData {
  labels: string[] = [];
  datasets: Array<ChartDatasets> = [];

  constructor(obj?: Partial<ChartData>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
