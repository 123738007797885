export default class ChartDatasets {
  label: string;
  data: number[] = [];
  fill: boolean = false;
  borderColor: string;
  backgroundColor: string = "transparent";
  yAxisID: string;
  borderWidth: number = 1;
  pointStyle: string = "circle";
  pointRadius: number = 3;
  pointBorderColor: string = "rgb(255, 255, 255)";

  constructor(obj?: Partial<ChartDatasets>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
